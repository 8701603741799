.toast {
  :global {
    .Toastify__toast {
      background-color: #081824;
      border-radius: 12px;
      box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.3);
    }

    .Toastify__toast-body {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
    }

    .Toastify__toast--default {
      color: #9ac9e3;
    }

    .Toastify__toast--warning {
      color: #f2dc8a;
    }

    .Toastify__toast--success {
      color: #2da64f;
    }

    .Toastify__toast--info {
      color: #9ac9e3;
    }

    .Toastify__toast--error {
      color: #f37272;
    }

    .Toastify__close-button {
      color: white;
      align-self: center;
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }
}
