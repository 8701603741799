.verifyWrap {
  position: relative;
}

.verifyWrap .block {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  cursor: grab;
}

.verifyWrap .block:active {
  cursor: grabbing;
}

.verifyWrap .sliderContainer {
  position: relative;
  text-align: center;
  width: 310px;
  height: 40px;
  line-height: 40px;
  margin-top: 15px;
  background: #f7f9fa;
  color: #45494c;
  border: 1px solid #e4e7eb;
}

.verifyWrap .sliderContainer_active .slider {
  height: 38px;
  top: -1px;
  border: 1px solid #486cd6;
}

.verifyWrap .sliderContainer_active .sliderMask {
  height: 38px;
  border-width: 1px;
}

.verifyWrap .sliderContainer_success .slider {
  height: 38px;
  top: -1px;
  border: 1px solid #0db87f;
  background-color: #0ca14a !important;
}

.verifyWrap .sliderContainer_success .sliderMask {
  height: 38px;
  border: 1px solid #0db87f;
  background-color: #d2f4ef;
}

.verifyWrap .sliderContainer_success .sliderIcon {
  background-position: 0 -26px !important;
}

.verifyWrap .sliderContainer_fail .slider {
  height: 38px;
  top: -1px;
  border: 1px solid #f57a7a;
  background-color: #f57a7a !important;
}

.verifyWrap .sliderContainer_fail .sliderMask {
  height: 38px;
  border: 1px solid #f57a7a;
  background-color: #fce1e1;
}

.verifyWrap .sliderContainer_fail .sliderIcon {
  top: 14px;
  background-position: 0 -82px !important;
}

.verifyWrap .sliderContainer_active .sliderText,
.sliderContainer_success .sliderText,
.sliderContainer_fail .sliderText {
  display: none;
}

.verifyWrap .sliderMask {
  position: absolute;
  left: 0;
  top: 204px;
  height: 40px;
  border: 0 solid #486cd6;
  background: #ffb705;
}

.verifyWrap .slider {
  position: absolute;
  top: 0px;
  left: 0;
  width: 40px;
  height: 40px;
  background: #ffb705;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  transition: background 0.2s linear;
  cursor: pointer;
  cursor: grab;
}

.verifyWrap .slider:active {
  cursor: grabbing;
}

.verifyWrap .slider:hover {
  background: #ed8a33;
}

.verifyWrap .sliderIcon {
  font-size: 18px;
  color: #000;
}

.verifyWrap .slider:hover .sliderIcon {
  color: #fff;
}

.verifyWrap .refreshIcon {
  position: absolute;
  right: 5px;
  top: 255px;
  /* width: 30px; */
  height: 30px;
  cursor: pointer;
  background-size: 32px;
}

.verifyWrap .loadingContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 310px;
  height: 155px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #45494c;
  z-index: 2;
  background: #edf0f2;
}

.verifyWrap .loadingIcon {
  width: 32px;
  height: 32px;
  margin-bottom: 10px;
  background-size: 32px;
  animation: loading-icon-rotate 0.8s linear infinite;
}

@keyframes loading-icon-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180deg);
  }
}
