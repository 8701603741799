@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Brutal Type';
  src: url('/fonts/BrutalType/BrutalType-Regular.otf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Brutal Type';
  src: url('/fonts/BrutalType/BrutalType-Medium.otf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Brutal Type';
  src: url('/fonts/BrutalType/BrutalType-Bold.otf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Brutal Type';
  src: url('/fonts/BrutalType/BrutalType-ExtraBold.otf');
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

* {
  box-sizing: border-box;
  font-family: 'Brutal Type', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:disabled {
  cursor: not-allowed;
}

@media only screen and (max-width: 768px) {
  [data-aos] {
    transition: none !important;
    transform: none !important;
    animation: none !important;
  }
}

.vi * {
  font-family: 'Montserrat', sans-serif;
}

.fixed.w-full {
  width: calc(100% - var(--removed-body-scroll-bar-size, 0px));
  margin-right: var(--removed-body-scroll-bar-size, 0px);
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #0f2f45;
  border: solid 1px transparent;
  border-radius: 100vh;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.IIV::-webkit-media-controls-play-button,
.IIV::-webkit-media-controls-start-playback-button {
  opacity: 0;
  pointer-events: none;
  width: 5px;
}
button[data-state='active'] {
  @apply text-primary/6 border-b-[2px];
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
